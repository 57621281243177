import * as React from "react"
import Error from "../components/Error";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {graphql} from "gatsby";

export const query = graphql`
{
  allSanityAgbs {
  edges{
    node{
        agbfile {
          asset {
            url
          }
        }
    }
  }
  }
  }
 `

const NotFoundPage = ({data}) => {
  return (
      <Layout agbs={data.allSanityAgbs.edges[0].node.agbfile.asset.url}>
          <SEO title={"404 | Matthey & Melchior"}/>

          <Error/>
      </Layout>
  )
}

export default NotFoundPage
