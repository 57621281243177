import React from "react";
import Div100vh from 'react-div-100vh'
import {Link} from "gatsby";


const Error = ({children}) => {

    return (
        <Div100vh className={"placeholder__outer"}>
            <div className={"placeholder__inner"}>
            <h1 className={"center"}>Error 404</h1>
            <div className="separator short"></div>
            <p className={"center"}>
                Diese Seite konnte nicht gefunden werden.
                <br/>
                <Link to={"/"}>Zurück zur <span className="underline">Homepage</span></Link>
            </p>
            </div>
        </Div100vh>
    )
}


export default Error
